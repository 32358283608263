/* CUSTOM CSS! */
@font-face {
  font-family: "Museo_Sans";
  src: url("../fonts/MuseoSans-100.otf") format("opentype");
}

@font-face {
  font-family: "Museo_Sans_300";
  src: url("../fonts/MuseoSans-300.otf") format("opentype");
}

@font-face {
  font-family: "Museo_Sans_400";
  src: url("../fonts/MuseoSans-300.otf") format("opentype");
}

@font-face {
  font-family: "Museo_Sans_600";
  src: url("../fonts/MuseoSans_700 2.otf") format("opentype");
}

body {
  font-family: 'Museo_Sans';
  color: #FFF !important;
  font-size: 15px !important;
  background: #DDEBED;
  /* 
    */

}

.wrapper-img {
  position: relative;
}

.img-on {
  position: absolute;
  right: -2%;
  top: -15px;
  transform: rotate(-7.37deg);
}


/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #FFFFFF;
  border-radius: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(3, 49, 85, 1);
  /*  warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  background: radial-gradient(1607.34% 332.13% at 57.14% -62.72%, #00E6FF 14.29%, #011A2F 70.34%);
  border-radius: 50px;
  border-radius: 10px;
  opacity: 0.3;
  cursor: pointer
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(3, 49, 85, 1);
  background: radial-gradient(1607.34% 332.13% at 57.14% -62.72%, #00E6FF 14.29%, #011A2F 70.34%);
  /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  opacity: 1;
}

.invert-100 {
  filter: invert(100%);
}

.invert-50 {
  filter: invert(50%);
}

.invert-0 {
  filter: invert(0%);
}

.personalizedicon {
  font-size: 15px !important;
}

.personalizedicon .icon_ClientesBlack_icon {
  background: url('../images/rent/ClientesBlack_icon.svg');
}

.personalizedicon:hover .icon_ClientesBlack_icon {
  background: url('../images/rent/ClientesColor_icon.svg');
}

.personalizedicon .icon_BajoBlack_Icon {
  background: url('../images/rent/BajoBlack_Icon.svg');
}

.personalizedicon:hover .icon_BajoBlack_Icon {
  background: url('../images/rent/BajoColor_Icon.svg');
}

.personalizedicon .icon_BitcoinBlack_icon {
  background: url('../images/rent/BitcoinBlack_icon.svg');
}

.personalizedicon:hover .icon_BitcoinBlack_icon {
  background: url('../images/rent/BitcoinColor_icon.svg');
}

.personalizedicon .icon_CarritoBlack_Icon {
  background: url('../images/rent/CarritoBlack_Icon.svg');
}

.personalizedicon:hover .icon_CarritoBlack_Icon {
  background: url('../images/rent/CarritoColor_Icon.svg');
}

.personalizedicon .icon_DescuentosBlack_Icon {
  background: url('../images/rent/DescuentosBlack_Icon.svg');
}

.personalizedicon:hover .icon_DescuentosBlack_Icon {
  background: url('../images/rent/DescuentosColor_Icon.svg');
}

.personalizedicon .icon_DashboardBlack_icon {
  background: url('../images/rent/DashboardBlack_icon.svg');
}

.personalizedicon .icon_DashboardColor_icon {
  filter:brightness(0);
  background: url('../images/rent/DashboardColor_icon.svg');
}

/* .personalizedicon:hover .icon_DashboardBlack_icon {
  background: url('../images/rent/DashboardColor_icon.svg');
} */

.personalizedicon .icon_EtheBlack_icon {
  background: url('../images/rent/EtheBlack_icon.svg');
}

.personalizedicon:hover .icon_EtheBlack_icon {
  background: url('../images/rent/EtheColor_icon.svg');
}

.personalizedicon .icon_FechaBlack_icon {
  background: url('../images/rent/FechaBlack_icon.svg');
}

.personalizedicon .icon_FlechaDespliegueDownBlack_icon {
  background: url('../images/rent/FlechaDespliegueDownBlack_icon.svg');
}

.personalizedicon:hover .icon_FlechaDespliegueDownBlack_icon {
  background: url('../images/rent/FlechaDespliegueDownColor_icon.svg');
}

.personalizedicon .icon_FlechaDespliegueLeftBlack_icon {
  background: url('../images/rent/FlechaDespliegueLeftBlack_icon.svg');
}

.personalizedicon:hover .icon_FlechaDespliegueLeftBlack_icon {
  background: url('../images/rent/FlechaDespliegueLeftColor_icon.svg');
}

.personalizedicon .FlechaDespliegueLeftColor_icon {
  filter:brightness(0);
  background: url('../images/rent/FlechaDespliegueLeftColor_icon.svg');
}

.pointer {
  cursor: pointer
}

.personalizedicon .icon_FlechaDespliegueUpBlack_icon {
  background: url('../images/rent/FlechaDespliegueUpBlack_icon.svg');
}

.personalizedicon:hover .icon_FlechaDespliegueUpBlack_icon {
  background: url('../images/rent/FlechaDespliegueUpColor_icon.svg');
}

.personalizedicon .icon_FotoUsuarioBlack_icon {
  background: url('../images/rent/FotoUsuarioBlack_icon.svg');
}

.personalizedicon:hover .icon_FotoUsuarioBlack_icon {
  background: url('../images/rent/FotoUsuarioColor_icon.svg');
}

.personalizedicon .icon_FlechaDespliegueRightBlack_icon {
  background: url('../images/rent/FlechaDespliegueRightBlack_icon.svg');
}

.personalizedicon:hover .icon_FlechaDespliegueRightBlack_icon {
  background: url('../images/rent/FlechaDespliegueRightColor_icon.svg');
}

.personalizedicon .icon_InversionMinimaBlack_Icon {
  background: url('../images/rent/InversionMinimaBlack_Icon.svg');
}

.personalizedicon:hover .icon_InversionMinimaBlack_Icon {
  background: url('../images/rent/InversionMinimaColor_Icon.svg');
}

.personalizedicon .icon_KeyBlack_icon {
  background: url('../images/rent/KeyBlack_icon.svg');
}

.personalizedicon .icon_ConfimationKeyBlack_icon {
  background: url('../images/rent/ConfimationKeyBlack_icon.svg');
}

.personalizedicon .icon_Key_icon {
  background: url('../images/rent/Key_icon.png');
}

.personalizedicon .icon_ConfimationKey_icon {
  background: url('../images/rent/RepetirContrasena_icon.png');
}

.personalizedicon .icon_MjsBlack_icon {
  background: url('../images/rent/MjsBlack_icon.svg');
}

.personalizedicon:hover .icon_MjsBlack_icon {
  background: url('../images/rent/MjsColor_icon.svg');
}

.personalizedicon .icon_MostrarKeyBlack_icon {
  background: url('../images/rent/MostrarKeyBlack_icon.svg');
}

.personalizedicon .icon_OcultarKeyBlack_icon {
  background: url('../images/rent/OcultarKeyBlack_icon.svg');
}

.personalizedicon .icon_MailBlack_icon {
  background: url('../images/rent/MailBlack_icon.svg');
}

.personalizedicon .icon_MercadosSecundariosBlack_icon {
  background: url('../images/rent/MercadosSecundariosBlack_icon.svg');
}

.personalizedicon:hover .icon_MercadosSecundariosBlack_icon {
  background: url('../images/rent/MercadosSecundariosColor_icon.svg');
}

.personalizedicon .icon_MisNftBlack_icon {
  background: url('../images/rent/MisNftBlack_icon.svg');
}

.personalizedicon .icon_MisNftColor_icon {
  filter:brightness(0);
  background: url('../images/rent/MisNftColor_icon.svg');
}

.personalizedicon:hover .icon_MisNftBlack_icon {
  background: url('../images/rent/MisNftColor_icon.svg');
}

.personalizedicon .icon_NombreBlack_icon {
  background: url('../images/rent/NombreBlack_icon.svg');
}

.personalizedicon .icon_NotificacionesBlack_icon {
  background: url('../images/rent/NotificacionesBlack_icon.svg');
}

.personalizedicon:hover .icon_NotificacionesBlack_icon {
  background: url('../images/rent/NotificacionesColor_icon.svg');
}

.personalizedicon .icon_OtrasCuentasBlack_icon {
  background: url('../images/rent/OtrasCuentasBlack_icon.svg');
}

.personalizedicon:hover .icon_OtrasCuentasBlack_icon {
  background: url('../images/rent/OtrasCuentasColor_icon.svg');
}

.personalizedicon .icon_OtrasCuentasBlack_icon {
  background: url('../images/rent/OtrasCuentasBlack_icon.svg');
}

.personalizedicon:hover .icon_OtrasCuentasBlack_icon {
  background: url('../images/rent/OtrasCuentasColor_icon.svg');
}

.personalizedicon .icon_PendienteBlack_Icon {
  background: url('../images/rent/PendienteBlack_Icon.svg');
}

.personalizedicon:hover .icon_PendienteBlack_Icon {
  background: url('../images/rent/PendienteColor_Icon.svg');
}

.personalizedicon .icon_PerfilUsuarioBlack_icon {
  background: url('../images/rent/PerfilUsuarioBlack_icon.svg');
}

.personalizedicon:hover .icon_PerfilUsuarioBlack_icon {
  background: url('../images/rent/PerfilUsuarioColor_icon.svg');
}

.personalizedicon .icon_PrecioSugeridoBlack_Icon {
  background: url('../images/rent/PrecioSugeridoBlack_Icon.svg');
}

.personalizedicon:hover .icon_PrecioSugeridoBlack_Icon {
  background: url('../images/rent/PrecioSugeridoColor_Icon.svg');
}

.personalizedicon .icon_PropiedadesBlack_icon {
  background: url('../images/rent/PropiedadesBlack_icon.svg');
}

.personalizedicon .icon_PropiedadesColor_icon {
  filter:brightness(0);
  background: url('../images/rent/PropiedadesColor_icon.svg');
}

.personalizedicon:hover .icon_PropiedadesBlack_icon {
  background: url('../images/rent/PropiedadesColor_icon.svg');
}

.personalizedicon .icon_RentLogoColor02_icon {
  background: url('../images/rent/RentLogoColor02_icon.svg');
}

.personalizedicon:hover .icon_RentLogoColor02_icon {
  background: url('../images/rent/RentLogoColor01_icon.svg');
}

.personalizedicon .icon_RentSimboloBlack_icon {
  background: url('../images/rent/RentSimboloBlack_icon.svg');
}

.personalizedicon:hover .icon_RentSimboloBlack_icon {
  background: url('../images/rent/RentSimboloColor_icon.svg');
}

.personalizedicon .icon_RentLogoColor01_icon {
  filter: brightness(0);
  background: url('../images/rent/RentLogoColor01_icon.svg');
}

.personalizedicon .icon_RentLogoColor03_icon {
  filter: brightness(0);
  background: url('../images/rent/RentSimboloColor_icon.svg');
}

.personalizedicon .icon_gobernanza {
  background: url('../images/rent/RentSimboloColor_icon.svg');
}

.personalizedicon .icon_security {
  background: url('../images/rent/RentSimboloColor_icon.svg');
}

.personalizedicon .icon_RentaAnualActualBlack_Icon {
  background: url('../images/rent/RentaAnualActualBlack_Icon.svg');
}

.personalizedicon:hover .icon_RentaAnualActualBlack_Icon {
  background: url('../images/rent/RentaAnualActualColor_Icon.svg');
}

.personalizedicon .icon_RentaAnualEstimadaBlack_Icon {
  background: url('../images/rent/RentaAnualEstimadaBlack_Icon.svg');
}

.personalizedicon:hover .icon_RentaAnualEstimadaBlack_Icon {
  background: url('../images/rent/RentaAnualEstimadaColor_Icon.svg');
}

.personalizedicon .icon_StakingBlack_icon {
  background: url('../images/rent/StakingBlack_icon.svg');
}

.personalizedicon:hover .icon_StakingBlack_icon {
  background: url('../images/rent/StakingColor_icon.svg');
}

.personalizedicon .icon_StripeBlack_icon {
  background: url('../images/rent/StripeBlack_icon.svg');
}

.personalizedicon:hover .icon_StripeBlack_icon {
  background: url('../images/rent/StripeColor_icon.svg');
}

.personalizedicon .icon_SubioBlack_Icon {
  background: url('../images/rent/SubioBlack_Icon.svg');
}

.personalizedicon:hover .icon_SubioBlack_Icon {
  background: url('../images/rent/SubioColor_Icon.svg');
}

.personalizedicon .icon_TransaccionesBlack_icon {
  background: url('../images/rent/TransaccionesBlack_icon.svg');
}

.personalizedicon .icon_TransaccionesColor_icon {
  filter:brightness(0);
  background: url('../images/rent/TransaccionesColor_icon.svg');
}

/* .personalizedicon:hover .icon_TransaccionesBlack_icon {
  background: url('../images/rent/TransaccionesColor_icon.svg');
} */

.personalizedicon .icon_UbicationBlack_icon {
  background: url('../images/rent/UbicationBlack_icon.svg');
}

.personalizedicon:hover .icon_UbicationBlack_icon {
  background: url('../images/rent/UbicationColor_icon.svg');
}

.personalizedicon .icon_MisVotacionesBlack_icon {
  background: url('../images/rent/MisVotacionesBlack_icon.svg');
}

.personalizedicon:hover .icon_MisVotacionesBlack_icon {
  background: url('../images/rent/MisVotacionesColor_icon.svg');
}

.personalizedicon .icon_Sexo_icon {
  background: url('../images/rent/Sexo_icon.svg');
  background-size: contain;
}

.personalizedicon .icon_Cel_icon {
  background: url('../images/rent/Cel_icon.svg');
  background-size: contain;
}

.personalizedicon .icon_Close_icon {
  background: url('../images/rent/Cerrar_Icon.svg');
  background-size: contain;
}

.personalizedicon .icon_Code_icon {
  background: url('../images/rent/barcode_icon.svg');
  background-size: contain;
}

.personalizedicon .icon_PrecioSugeridoColor_Icon {
  filter:brightness(0);
  background: url('../images/rent/PrecioSugeridoColor_Icon.svg');
}

.personalizedicon .icon_DescuentosColor_Icon {
  filter:brightness(0);
  background: url('../images/rent/DescuentosColor_Icon.svg');
}

.personalizedicon .icon_InversionMinimaColor_Icon {
  filter:brightness(0);
  background: url('../images/rent/InversionMinimaColor_Icon.svg');
}

.personalizedicon .icon_RentaAnualEstimadaColor_Icon {
  filter:brightness(0);
  background: url('../images/rent/RentaAnualEstimadaColor_Icon.svg');
}

.personalizedicon .icon_UbicationColor_icon {
  filter:brightness(0);
  background: url('../images/rent/UbicationColor_icon.svg');
}

.personalizedicon2 .incon-soon {
  background: url('../images/rent/ComingSoon02_Icon_1@2x1.png');
  width: 46px;
  height: 32px;
  float: right;
}

.personalizedicon .icon_Sexo_icon_white {
  background: url('../images/rent/Sexo_icon_white.png');
  background-size: contain;
}

.personalizedicon .icon_Cel_icon_white {
  background: url('../images/rent/Cel_icon_white.png');
  background-size: contain;
}

.personalizedicon .icon_FechaNacimiento_icon_white {
  background: url('../images/rent/FechaNacimiento_icon_white.png');
  background-size: contain;
}

.personalizedicon .icon_Usuario_icon_white {
  background: url('../images/rent/Usuario_icon_white.png');
  background-size: contain;
}

.personalizedicon .icon_Correo_icon_white {
  background: url('../images/rent/Correo_icon_white.png');
  background-size: contain;
}


iframe {
  border: none;
  overflow: hidden;
}

.w-100 {
  width: 100% !important;
}

tr:nth-child(even) {
  background-color: #F0F8FA;
  border-top: 1px solid #A6B8BE;
}
tr:nth-child(odd) {
  background-color: #FFF;
  border-top: 1px solid #A6B8BE;
}
.table thead th{
  background-color: #17375B !important;
}

.h-300 {
  height: 500px !important;
}

.wx-50 {
  width: 50px;
  height: 30px;
}

.w-30 {
  width: 30px;
  height: 30px;
}

.w-20 {
  width: 20px;
  height: 20px;
}

.w-15 {
  width: 15px;
  height: 15px;
}

.w-10 {
  width: 10px;
  height: 10px;
}

/* HELPERS */
.mt-50 {
  margin-top: 50px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.p-50 {
  padding: 50px;
}

.p-5px {
  padding: 5px;
}

.p-100 {
  padding: 100px;
}

.b-t {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}

.b-b {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.b-l {
  border-left: 1px solid rgba(120, 130, 140, 0.13);
}

.b-r {
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}

.b-all {
  border: 1px solid rgba(120, 130, 140, 0.13);
}

.b-none {
  border: 0px !important;
}

.max-height {
  height: 310px;
  overflow: auto;
}

.p-0 {
  padding: 0px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-l-0 {
  padding-left: 0px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.p-t-0 {
  padding-top: 0px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-t-60 {
  padding-top: 60px !important;
}

.p-t-70 {
  padding-top: 70px !important;
}

.p-b-0 {
  padding-bottom: 0px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-b-60 {
  padding-bottom: 60px !important;
}

.m-0 {
  margin: 0px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-0 {
  margin-top: 0px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-t-67 {
  margin-top: 67px !important;
}

.m-t-60 {
  margin-top: 60px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.vt {
  vertical-align: top;
}

.vb {
  vertical-align: bottom;
}

.fs-11 {
  font-size: 11px;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: normal;
}

.font-light {
  font-weight: 300;
}

.pull-in {
  margin-left: -15px;
  margin-right: -15px;
}

.b-0 {
  border: none !important;
}

.vertical-middle,
.vm {
  vertical-align: middle;
}

.text-black {
  color: black;
}

.mdi {
  font-size: 17px;
}

.dn {
  display: none !important;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

/* END HELPERS */

.card {
  border-radius: 30px;
}

.logo {
  width: 180px;
  margin-top: 9px;
  margin-bottom: 45px;
}

.login-card h1 {
  font-family: 'Museo_Sans';
  font-style: normal;
  font-weight: 250;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.665em;
  margin-bottom: 45px !important;
}

.login-card .form-control {
  width: calc(100% - 50px) !important;
  display: inline-block;
}

.login-card .form-control.typepassword {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  width: calc(100% - 90px) !important;
  border-right: none;
  ;
}

.input-group-btn {
  
  background: #FFFFFF;
  box-shadow: inset 0px 0px 10px -3px rgba(0, 0, 0, 0.67);
  float: right;
  height: 50px;
  line-height: 50px;
  padding-top: 15px;
  cursor: pointer;
}

.form-control {
  height: 45px !important;
  /* padding: 1.2rem 1rem !important; */
  background: #FFFFFF;
  border: 1px solid #17375B;
  border-radius: 5px;
}

.personalizedicon {
  float: left;
  margin-right: 20px;
  padding-top: 6px;
}

.personalizedicon2 {
  float: right;
  margin-top: -4px;
}

.personalizedicon div {
  background-repeat: no-repeat !important;

}

.input-group-btn .personalizedicon {
  margin-right: 0px;
  width: 40px;
  padding: 7px;
}


.btn-rent {
  background-color: transparent !important;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: flex-end;
}

.center-th-td {
  text-align: center;
}

.btn-retiro {
  width: 100px;
}

.btn-rent-invert-prueba{
  background-color: #17375B;
  color:#FFF !important;
  box-shadow: 1.00211px 2.00423px 25.0529px rgba(23, 55, 91, 0.2);
  border-radius: 2.50529px;
}

.btn-rent-white {
  font-family: 'Museo_Sans_300';
  background-color: #FFFFFF !important;
  transition: 300ms all;
  font-weight: 500;
  font-size: 15px !important;
  line-height: 18px;
  border: 1.7px solid #011A2F;
  color: #011A2F !important
}

.btn-rent-white-modal {
  font-family: 'Museo_Sans_300';
  background-color: #FFFFFF !important;
  transition: 300ms all;
  margin-right: auto;
  margin-left: auto;
  display: block;
  width: 40%;
  font-weight: 500;
  font-size: 15px !important;
  line-height: 18px;
  border: 1.7px solid #011A2F;
  color: #011A2F !important
}

.btn-rent-white:hover {
  background-color: #E6E8EA !important;
}

.footerLogin {
  margin-top: 54px;
}

a {
  color: #00A2EA;
}

.LoginBp {
  background: URL("../images/LoginBp.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.CreaCtaBp {
  background: URL("../images/CreaCtaBp.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
}

/*  SIDEBAR  */
.bg-gradient-primary {

  /* 
  background: rgb(1,26,47) !important;
  background: -moz-linear-gradient(0deg, rgba(1,26,47,1) 0%, rgba(50,207,232,1) 100%) !important;
  background: -webkit-linear-gradient(0deg, rgba(1,26,47,1) 0%, rgba(50,207,232,1) 100%) !important;
  background: linear-gradient(0deg, rgba(1,26,47,1) 0%, rgba(50,207,232,1) 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#011a2f",endColorstr="#32cfe8",GradientType=1) !important; 
  
  background: rgb(3,49,85,1) !important;
  */
  /* 
    */
  background: radial-gradient(farthest-corner at 150px 0px, rgba(50, 207, 232, 1) 0%, rgba(2, 0, 36, 1) 60%, rgba(2, 0, 36, 1) 100%);
  background-size: 300% 100%;
  background-repeat: no-repeat;

}

.sidebarToggle {
  background: rgba(3, 49, 85, 1);
  /* 
    */
  background: -moz-linear-gradient(0deg, rgba(3, 49, 85, 1) 0%, rgba(59, 235, 246, 1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(3, 49, 85, 1) 0%, rgba(59, 235, 246, 1) 100%);
  background: linear-gradient(0deg, rgba(3, 49, 85, 1) 0%, rgba(59, 235, 246, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#033155", endColorstr="#3bebf6", GradientType=1);
  height: 100vh;
  border: none;
  color: #FFF;
  padding-left: 4px;
  padding-right: 4px;
  transition: 300ms all;
  opacity: 0.9;


  position: fixed;
  z-index: 1;
  top: 0;


}

.sidebarToggle:hover {
  opacity: 1;
}

#wrapper {
  /* 
  */
  background: rgb(4, 49, 70) !important;
  background: -moz-linear-gradient(0deg, rgba(4, 49, 70, 1) 0%, rgba(32, 147, 170, 1) 100%) fixed !important;
  background: -webkit-linear-gradient(0deg, rgba(4, 49, 70, 1) 0%, rgba(32, 147, 170, 1) 100%) fixed !important;
  background: linear-gradient(0deg, rgba(4, 49, 70, 1) 0%, rgba(32, 147, 170, 1) 100%) fixed !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#043146", endColorstr="#2093aa", GradientType=1) fixed !important;

}

#wrapper #content-wrapper,
#content,
footer.bg-white {
  background-color: #DEEBEE !important;
  color: #000 !important;
}

#content {
  padding-left: 70px;
  padding-right: 45px;
}

.shadow_header {
  box-shadow: none !important;
}

.default-select {
  line-height: 0px;
  
  transition: 300ms all;
  color: #FFF;
}

.default-select:hover {
  cursor: pointer;
}

.navbar {
  padding: 0rem;

}

.topbar {
  height: 100px;
  position: fixed;
  top: 0px;
  z-index: 1;

}

.noright {
  border-right: 0px !important;
}

.form-head h2 {
  font-size: 35px;
  color: #101C2E;
}

.image-widget {
  margin-right: 15px;
}

@media (min-width: 768px) {
  .sidebar {
    width: 17rem !important;
  }
}

.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.topbar.navbar-light .navbar-nav .nav-item .nav-link {
  color: #101C2E !important;
  font-size: 18px;
  font-family: 'Museo_Sans_600';
}


.headercoin {
  border-right: 1px solid #FFF !important;
  border-radius: 0px !important;
  border-left: 0px !important;
  padding-right: 15px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background: linear-gradient(144.38deg, #FFFFFF 24.61%, #DFEBEE 103.34%);
  box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  background-color: rgba(2, 141, 166, 0.7);
  transition: 300ms all;
}

.card:hover {
  /* background-color: rgba(2, 141, 166,  1); */
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.75rem - 1px);
  border-bottom-left-radius: calc(0.75rem - 1px);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link+.card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.75rem - 1px) calc(0.75rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.75rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.75rem - 1px);
  border-bottom-left-radius: calc(0.75rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group>.card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}



.card {
  margin-bottom: 1.175rem;
  transition: all .5s ease-in-out;
  position: relative;
  border: 0px solid transparent;
  border-radius: 0.35rem;
  box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 575px) {
  .card {
    margin-bottom: 0.938rem;
    height: calc(100% - 0.938rem);
  }
}

.card-body {
  padding: 1.175rem;
}

@media only screen and (max-width: 575px) {
  .card-body {
    padding: 1rem;
  }
}

.card-title {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
}

.card-title--large {
  font-size: 1.5rem;
}

.card-title--medium {
  font-size: 1rem;
}

.card-title--small {
  font-size: 0.875rem;
}

.card-header {
  border-color: #EEEEEE;
  position: relative;
  background: transparent;
  padding: 1.5rem 1.875rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .card-header {
    padding: 1.25rem 1rem 1.25rem;
  }
}

[data-theme-version="dark"] .card-header {
  border-color: #473F72;
}

.card-header .card-title {
  margin-bottom: 0px;
}

.card-header .subtitle {
  padding-top: 5px;
  font-size: 14px;
  line-height: 1.5;
}

.card-footer {
  border-color: #EEEEEE;
  background: transparent;
  padding: 1.25rem 1.875rem 1.25rem;
}

[data-theme-version="dark"] .card-footer {
  border-color: #473F72;
}

.transparent-card.card {
  background: transparent;
  border: 1px solid transparent;
  box-shadow: none;
}

.card-action>a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  border-color: transparent;
  text-align: center;
  background: var(--primary-dark);
  color: #fff;
  margin-right: 8px;
}

[data-theme-version="dark"] .card-action>a {
  background: #2DBFD4;
}

.card-action>a:last-child {
  margin-right: 0;
}

.card-action>a:hover,
.card-action>a:focus {
  background: var(--primary-dark);
}

[data-theme-version="dark"] .card-action>a:hover,
[data-theme-version="dark"] .card-action>a:focus {
  background: #2DBFD4;
}

.card-action>a i,
.card-action>a span {
  font-size: 1rem;
}

.card-action .dropdown {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border-color: transparent;
  text-align: center;
  margin-right: 8px;
  top: -2px;
  position: relative;
  display: inline-block;
  background: var(--primary-dark);
  color: var(--primary);
}

[data-theme-version="dark"] .card-action .dropdown {
  background: #2DBFD4;
}

.card-action .dropdown:hover,
.card-action .dropdown:focus {
  background: var(--primary-dark);
}

[data-theme-version="dark"] .card-action .dropdown:hover,
[data-theme-version="dark"] .card-action .dropdown:focus {
  background: #2DBFD4;
}

.card-action .dropdown .btn {
  padding: 0;
  line-height: 27px;
  color: #fff;
}

.card-action .dropdown .btn:focus {
  box-shadow: none;
}

.card-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  overflow: auto;
}

.card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.card-loader i {
  margin: 0 auto;
  background: var(--primary-dark);
  padding: 10px;
  border-radius: 50%;
  color: #fff;
  font-size: 1rem;
}

.rotate-refresh {
  -webkit-animation: mymove 0.8s infinite linear;
  animation: mymove 0.8s infinite linear;
  display: inline-block;
}

.card-header .date_picker {
  display: inline-block;
  padding: 8px;
  border: 1px solid #EEEEEE;
  cursor: pointer;
  border-radius: .375rem;
}

.card-header .border-0 {
  padding-bottom: 0;
}


.card-bx img {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  border-radius: 1.25rem;
  object-fit: cover;
}

.card-bx .card-info {
  position: relative;
  padding: 40px 30px;
}

.card-bx .card-info .num-text {
  font-size: 28px;
}


.card-coin h2 {
  font-size: 30px;
}

@media only screen and (max-width: 1600px) {
  .card-coin h2 {
    font-size: 22px;
  }
}

.contenedor {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contenedor figure {
  position: relative;
  cursor: pointer;
  width: 350px;
  overflow: hidden;


}

.contenedor figure img {
  width: 100%;
  transition: all 400ms ease-out;
  will-change: transform;
}

.contenedor figure .capa {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 400ms ease-out;
  opacity: 1;
  visibility: visible;
  text-align: center;
  background: rgba(52, 216, 247, 0.5);
}

.contenedor figure:hover>.capa {
  opacity: 0;

  visibility: hidden;
}

.btn {
  /* padding: 15.0333px 40.3167px; */
}

.iconLogo {
  width: 4.6rem;
  height: 4.6rem;
  margin-right: 1rem !important;
}

@media only screen and (max-width: 1600px) {
  .iconLogo {
    width: 2.3rem;
    height: 2.3rem;
    margin-right: 0.25rem !important;
  }
}

.product-detail-content h4 {
  font-size: 18px;
  font-family: 'Museo_Sans_600';
}

li {
  list-style: none;
}

.star-rating {
  margin-top: -15px;
}

.star-rating li {
  display: inline-block;
}

.star-rating li i {
  color: gold;
}

.text-rent {
  color: #101C2E;
  font-size: 16px;
  font-family: 'Museo_Sans_400';
}

.btn-rent-invert {
  background-color: #62C5E3;
  color:#101C2E;
  border: 1px solid #62C5E3;
  transition: 300ms all;
  padding: 10px 40px;
  border-radius: 4px;
  font-family: 'Museo_Sans_600';
  text-decoration: none;
}

.btn-rent-invert:hover {
  background-color: #62C5E3;
  border: 1px solid #62C5E3;
  transform: translateY(-2px);
  text-decoration: none;
  box-shadow: 0px 2px 0px 2px #62c5e348;
  color:#101C2E;
  text-decoration: none;
}

.btn-rent-invert-second {
  text-decoration: none;
  color: #fff;
  background-color: transparent!important;
  border: 1px solid #62C5E3;
  color:#101C2E;
  box-shadow: 0px 2px 0px 2px #62c5e348;
  border-radius: 4px;
  font-family: 'Museo_Sans_600';
  transition: 300ms all;
}

.btn-rent-invert-second:hover {
  transform: translateY(-2px);
}

.btn-rent-invert:focus, .btn-rent-invert.focus {
  text-decoration: none;
  color: #fff;
  background-color: transparent!important;
  border: 1px solid #62C5E3;
  color:#101C2E;
  box-shadow: 0px 2px 0px 2px #62c5e348;
}


.btn-rent-invert-comission {
  background-color: #00DBF4;
  border: 1px solid #00DBF4;
}

.modal-title.h4,
.modal-body {
  color: #000000;
  font-family: 'Museo_Sans_600';
}

h4{
  font-family: 'Museo_Sans_600';
  margin-bottom: 0px !important;
}
h3{
  font-family: 'Museo_Sans_600';
  margin: 10px 0px 30px;
  font-size: 25px;
  line-height: 30px;
}

.dialog {
  position: fixed;
  background-color: rgba(30, 143, 166, 0.5);
  border: none;
  top: 0%;
  z-index: 999;
  width: 100%;
  height: 100vh;
  padding-top: 10%;
  padding-left: calc(60% - 500px);
  backdrop-filter: blur(2px);
  margin: 0 auto;
}

.image {
  width: 500px;
}

.my-10 {
  margin-top: 10rem !important;
}

.my-6 {
  margin-top: 6rem !important;
}

.box-cotizador {
  padding: 0px;
  border-radius: 10px;

}

.body_cotizador {
  padding: 0px;
  
  padding-bottom: 30px;

}

.header_cotizador {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-bottom: 20px;
}

.footer_cotizador {

  width: 100%;
  height: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.text-gray {
  color: #666 !important;
}


.cart {
  display: flex;
  display: -webkit-flex;
  margin-left: 64px;
  position: relative;
  z-index: 99;
}

@media (max-width: 991px) {
  .cart {
    margin-left: 16px;
  }

  .cart-info {
    display: none;
  }
}

.cart-info table {
  font-size: 14px;
  color: #08E5F4;
  text-align: right;
}

.cart-info table tr {
  padding: 0;
  margin: 0;
}

.cart-info table td {
  padding: 0 4px;
  line-height: 16px;
}

.cart-icon {
  margin-left: 16px;
  z-index: 99;
  position: relative;
}

@media (min-width: 992px) {
  .cart-count {
    display: none;
  }
}

@media (max-width: 991px) {
  .cart-count {
    display: block;
    position: absolute;
    background: #08E5F4;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    width: 24px;
    border-radius: 50%;
    top: -6px;
    right: -9px;
  }
}

.cart-preview {
  display: none;
  background: #FFF;
  border: 1px solid #08E5F4;
}

.cart-preview:before {
  content: "";
  position: absolute;
  display: block;
  top: -9px;
  right: 8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #666;
  z-index: 1;
}

.cart-preview:after {
  content: "";
  position: absolute;
  display: block;
  top: -7px;
  right: 9px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #FFF;
  z-index: 1;
}

.cart-preview.active {
  z-index: 99;
  display: block;
  position: absolute;
  top: 48px;
  right: 0;
  width: 360px;
  height: 388px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  /* @media (max-width: 400px) {
    top: 45px;
    width: 100%;
    min-width: 300px;
    & > div {
      width: 100% !important;
    }
    .cart-items {
      width: 100%;
    }
    .stepper-input {
      a {
        display: none;
      }
    }
  } */
}

.action-block {
  background: #FFF;
  position: absolute;
  bottom: 0;
  padding: 16px;
  width: 100%;
}

.action-block button {
  background: #08E5F4;
  display: block;
  width: 100%;
}

.action-block button.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.empty-cart {
  text-align: center;
}

.empty-cart img {
  width: 100%;
  max-width: 250px;
  margin: 42px auto 16px auto;
}

h2 {
  color: #666;
  font-size: 18px;
}

.cart-items {
  height: 320px;
  overflow-y: auto;
  width: 360px;
  padding-left: 0px
}

.cart-item {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  padding: 8px;
}

.cart-item .product-image {
  width: 48px;
  height: 48px;
}

.cart-item .product-info {
  margin-left: 16px;
  flex-grow: 1;
}

.cart-item .product-info .product-name {
  color: #666;
  font-size: 14px;
}

.product-price {
  color: #666;
  font-weight: 700;
}

.product-price:before {
  content: "$ ";
}

.product-total {
  margin-left: 16px;
}

.product-total .quantity {
  color: #666;
  font-size: 14px;
  text-align: right;
}

.amount {
  color: #222;
  font-weight: 700;
  text-align: right;
}

.amount:before {
  content: "$ ";
}

.product-remove {
  margin-left: 24px;
  height: 24px;
  line-height: 24px;
  width: 24px;
  font-size: 22px;
  color: #666;
  background-color: #FFF;
  text-align: center;
  padding: 0;
  border: none;
}

.product-remove:hover {
  background: #08E5F4;
  color: #FFF;
}


@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
    opacity: 0;
  }

  10%,
  20% {
    transform: scale3d(0.8, 0.66, 0.66) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

.tada {
  animation-name: tada;
  animation-duration: 300ms;
  animation-fill-mode: both;
}

.walletValues {
  width: 300px;
  overflow-x: hidden;
}





.checkout-page .container .order-details {
  flex-grow: 1;
}

.checkout-page .container .order-summary {
  flex-basis: 300px;
}

.timeline {
  text-align: center;
}

.timeline li {
  display: inline-block;
  width: 120px;
  cursor: pointer;
  position: relative;
}

.timeline li :after {
  content: "";
  width: 120px;
  height: 2px;
  background: #666;
  display: block;
  position: absolute;
  bottom: 12px;
  z-index: -1;
}

.timeline li :after {
  width: 60px;
  left: 60px;
}

.timeline li :after {
  width: 60px;
}

.timeline li h2 {
  padding: 16px 0;
  text-align: center;
  font-size: 16px;
  color: #ddd;
}

.timeline li i {
  font-size: 24px;
  color: #666;
  border-radius: 15px;
  background: #FFF;
  border: 2px solid #666;
}

.timeline li.done {
  color: #00A2EA;
}

.timeline li.done i {
  color: #00A2EA;
}

.timeline li.done h2 {
  color: #00A2EA;
}

.timeline li.active {
  color: #00A2EA;
}

.timeline li.active i {
  color: #00A2EA;
}

.timeline li.active h2 {
  color: #00A2EA;
}

.order-details {
  padding: 24px;
  background: #FFF;
  margin: 24px 24px 24px 0;
  border-radius: 6px;
  z-index: 1;
}

.detail-container {
  margin-top: 24px;

}

.detail-container .actions {
  display: flex;
  justify-content: space-between;
}

.detail-container .actions button {
  border-radius: 8px;
  height: 48px;
}

.detail-container .actions button i {
  font-size: 20px;
  vertical-align: middle;
}

.detail-container .actions button i:first-child {
  background-color: transparent;
  color: #00A2EA;
}

.detail-container .actions button i:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.detail-container h2 {
  margin: 32px 0;
  text-align: center;
}

.detail-container .auth-message {
  text-align: center;
  padding: 48px 24px;
  border: 1px solid #fff;
  background: #ddd;
  margin-bottom: 24px;
  border-radius: 8px;
}

.detail-container .auth-message p {
  margin-bottom: 24px;
}

.detail-container .auth-message p span {
  padding: 4px;
  background: #666;
  border-radius: 4px;
}

.detail-container button {
  background: transparent;
  color: #00A2EA;
  border: 1px solid #00A2EA;
  border-radius: 8px;
}

.order-summary {
  padding: 24px;
  background: #FFF;
  margin: 24px 0;
  border-radius: 6px;
}

.order-summary h2 {
  padding: 16px;
}

.order-summary h2 span {
  color: #ddd;
  font-weight: normal;
}

.order-summary .cart-items {
  width: 300px;
  max-height: 256px;
  overflow-y: auto;
}

.order-summary .total-breakup {
  border-top: 1px solid #666;
  padding-top: 24px;
  margin-top: 16px;
}

.order-summary .total-breakup li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.order-summary .total-breakup li h2 {
  padding: 16px 0;
}

/* SIDEBAR */

.sidenav {
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 20px;
  overflow: hidden;
  overflow-y: auto !important;
}

.sidenav::-webkit-scrollbar {
  width: 2px;
}

.sidenav::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(3, 49, 85, 1);
  background: rgb(3, 49, 85, 1);
  border-radius: 12px;
}

.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.main {
  margin-left: -10px;
  /* Same as the width of the sidenav */
  padding: 0px 0px;
}

.pt-body {
  margin-top: 160px;
}

.bg-blue {
  width: 100%;
  height: 120px;
  position: absolute;
  border-bottom: 1px solid #CCCCCC;
  transition: 300ms all;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

.ReactModal__Content {

  background-color: #FFF !important;
  box-shadow: -36px 73px 124px -11px rgba(1, 26, 47, 0.67) !important;
  border-radius: 30px !important;
}

.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(10px);

  /* Add the blur effect */
}

.modal_Box {
  width: 450px !important;
  padding-left: 25px;
  padding-right: 25px;
  color: #011A2F;

}

.modal_Box .modal_header {
  border-bottom: 1px solid black;
  padding-bottom: 5px;
}

.modal_Box h2 {
  font-family: "Museo_Sans_600";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

}

.modal_Box .modal_body {
  padding-top: 27px
}

.modal_Box .alertmessage {
  width: 100%;
  margin-bottom: 10px;
}

.ReactModal__Overlay--after-open {
  z-index: 1 !important;
}

.radius-7 {
  border-radius: 7px !important;
}

.btn-modal {
  box-sizing: border-box;
  position: absolute;
  height: 46px;
  border: 1.5px solid #011A2F;
  border-radius: 122px;
  width: 80%;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  font-family: 'Museo_sans_300';
  font-size: 14px;
}

.table thead th,
.jzGaAN {
  vertical-align: bottom !important;
  border-bottom: 2px solid #17375B !important;
  background-color: #17375B !important;
  border-top: none !important;;
  font-size: 14px !important;
  color: #62C5E3 !important;
  font-weight: bold !important;
}

.table thead tr th:first-child {
  border-top-left-radius: 15px !important;
  padding-left: 20px;
}

.table thead tr th:last-child {
  border-top-right-radius: 15px !important;
  padding-right: 20px;
}

.table {
  border-top-right-radius: 20px;
}

table .avatartable {
  width: 40px;
  border-radius: 40px;
  display: inline-block;
}

table span {
  display: inline-block;
}

.nav-tabs {
  border-bottom: none !important;
}

.nav-tabs a {
  color: white;
}

.jxflYm {
  background-color: #0F5E7A !important;
  color: #FFF !important;
  border: none !important;
}

.bvxQGL:disabled {
  cursor: unset;
  color: rgba(255, 255, 255, .18);
  fill: rgba(255, 255, 255, .18);
}

.bvxQGL {
  color: #FFF !important;
}

.table-responsive {
  background-color: #0F5E7A !important;
}

.jVeafb {
  background-color: #0F5E7A !important;
  color: #FFFFFF !important;
}

/* MEDIAS QUERY MOBILE VERSION */
@media only screen and (max-width: 575px) {
  .visible-xs {
    display: block !important;
  }

  .hidden-xs {
    display: none !important;
  }

  .login-card .form-control {
    width: 100% !important;
    display: inline-block;
  }

  .login-card .form-control.typepassword {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: calc(100% - 40px) !important;
    border-right: none;
  }

  .ReactModal__Content {
    width: 100% !important;
    padding: 10px;
  }

  .modal_Box {
    width: 100% !important;
    padding-left: 0px;
    padding-right: 0px;
  }

  .modal_Box .col-sm-3 {
    width: 25% !important;
  }

  .modal_Box .col-sm-6 {
    width: 50% !important;
  }

  .btn-modal,
  .btn-rent-invert {
    font-size: 0.8rem;
  }

  .content-body {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .topbar {
    width: 100% !important;
  }

  .bg-blue {
    margin-left: 0px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .dynamic_bar {
    margin-top: 179px !important;
    padding-left: 5px !important;
    padding-top: 5px !important;
    padding-bottom: 0px !important;
    padding-bottom: 0px !important;
    margin-left: -5px !important;

  }

  .sidebar {
    width: 100% !important;
  }

  .sidebar .nav-item .nav-link {
    text-align: left;
    padding: 1rem;
    width: 100%;
  }
}

.hidden-xs {}

.visible-xs {
  display: none
}

#sidebarToggleTop.btn-link {
  color: #fff !important;
}

.w80 {
  width: 80px;
  height: 80px;
  margin-top: -20px;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.footerLinks {
  font-size: 0.85rem !important;
  text-align: left;
  color: white;
}

.rsis-container div, .rsis-image{
  background-size: contain !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.custom-file-container {
  position: relative;
}

.custom-file-upload {
  padding: 3px 7px;
  background-color: #00DBF4;
  color: #fff;
  border: 1px solid #00DBF4;
  border-radius: 15px;
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
  text-align: center;
  float: right;
}

.custom-file-upload-pro {
  padding: 3px 7px;
  background-color: #00DBF4;
  color: #fff;
  border: 1px solid #00DBF4;
  border-radius: 15px;
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
  text-align: center;
  float:left
}



.custom-file-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}


.grid-container-three-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  grid-template-rows: auto auto; 
  gap: 20px; 
}

.grid-container-two-columns{
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  grid-template-rows: auto auto; 
  gap: 20px;
}

.grid-item {
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 10px;
}
.grid-title{
  font-family:'Museo_Sans_600';
  color:#cccccca9
}
.grid-content{
  font-family:'Museo_Sans_600';
}
.nav-tabs .nav-link.active {
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.nav-tabs .nav-link:hover {
  border-radius: 10px;
}

.cube-loader-container{
  display: grid; 
  place-items: center;
  padding: 90px;
}

.cube-loader {
  position: relative;
  width: 75px;
  height: 75px;
  transform-style: preserve-3d;
  transform: rotateX(-30deg);
  animation: animate 4s linear infinite;
  margin: 0 auto;
}

@keyframes animate {
  0% {
    transform: rotateX(-30deg) rotateY(0);
  }

  100% {
    transform: rotateX(-30deg) rotateY(360deg);
  }
}

.cube-loader .cube-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}

.cube-loader .cube-wrapper .cube-span {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(calc(90deg * var(--i))) translateZ(37.5px);
  background: linear-gradient(
    to bottom,
    #00DBF4 0%,
    #00DBF4 5.5%,
    #00DBF4 12.1%,
    #00DBF4 19.6%,
    #00DBF4 27.9%,
    #00DBF4 36.6%,
    #00DBF4 45.6%,
    #00DBF4 54.6%,
    #00DBF4 63.4%,
    #00DBF4 71.7%,
    #00DBF4 79.4%,
    #00DBF4 86.2%,
    #00DBF4.9%,
    #00DBF4 96.3%,
    #00DBF4 99%,
    #00DBF4 100%
  );
}

.cube-top {
  position: absolute;
  width: 75px;
  height: 75px;
  background: #00DBF4 0%;
                      /* width 75px / 2 = 37.5px */
  transform: rotateX(90deg) translateZ(37.5px);
  transform-style: preserve-3d;
}

.cube-top::before {
  content: '';
  position: absolute;
  width: 75px;
  height: 75px;
  background: hsl(176.61, 42.28%, 40.7%) 19.6%;
  transform: translateZ(-90px);
  filter: blur(10px);
  box-shadow: 0 0 10px #323232,
              0 0 20px hsl(176.61, 42.28%, 40.7%) 19.6%,
              0 0 30px #323232,
              0 0 40px hsl(176.61, 42.28%, 40.7%) 19.6%;
}

.cube-loader .cube-wrapper .cube-span::after,
.cube-top::after {
  content: url('../images/rent/LogoRent.png');
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px; 
  height: 60px;
  transform: translate(-50%, -50%);
  opacity: 0.3;
  z-index: 10;
  pointer-events: none;
  background-size: contain;
  overflow: hidden;
}


.cube-loader .cube-wrapper .cube-span-0 {
  transform: rotateY(0deg) translateZ(37.5px);
}

.cube-loader .cube-wrapper .cube-span-1 {
  transform: rotateY(90deg) translateZ(37.5px);
}

.cube-loader .cube-wrapper .cube-span-2 {
  transform: rotateY(180deg) translateZ(37.5px);
}

.cube-loader .cube-wrapper .cube-span-3 {
  transform: rotateY(270deg) translateZ(37.5px);
}