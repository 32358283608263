
  .deznav ul {
    padding: 0;
    margin: 0;
    list-style: none; }
  .deznav .main-profile {
    text-align: center;
    padding: 20px 0px;
    position: relative; }
    .deznav .main-profile .image-bx {
      position: relative;
      display: inline-block;
      height: 50px;
      width: 50px;
      margin-bottom: 12px; }
      .deznav .main-profile .image-bx img {
        border-radius: 8px;
        height: 100%;
        width: 100%;
        object-fit: cover; }
      .deznav .main-profile .image-bx a {
        color: var(--primary);
        position: absolute;
        top: -10px;
        right: -8px;
        font-size: 20px; }
    .deznav .main-profile .name {
      margin-bottom: 2px; }
    .deznav .main-profile .email {
      margin-bottom: 0;
      font-size: 12px; }
  .deznav .metismenu {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 15px; }
    .deznav .metismenu.fixed {
      position: fixed;
      top: 0;
      width: 100%;
      left: 0; }
    .deznav .metismenu > li {
      display: flex;
      flex-direction: column; }
      .deznav .metismenu > li a {
        position: relative; }
        .deznav .metismenu > li a > i {
          font-size: 1.2rem;
          display: inline-block;
          vertical-align: middle;
          padding: 0 1rem 0 0;
          position: relative;
          top: 0;
          -webkit-transition: all 0.5s;
          -ms-transition: all 0.5s;
          transition: all 0.5s;
          line-height: 1; }
          [data-sidebar-style="compact"] .deznav .metismenu > li a > i {
            display: block;
            padding: 0;
            background: #F9F9F9;
            color: rgba(0, 0, 0, 0.6);
            width: 60px;
            height: 60px;
            border-radius: 12px;
            line-height: 60px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 5px; }
            [data-sidebar-style="compact"] .deznav .metismenu > li a > i[data-theme-version="dark"] {
              color: #fff; }
          @media only screen and (max-width: 767px) {
            .deznav .metismenu > li a > i {
              font-size: 1.25rem;
              padding: 0 .75rem 0 0;
              color: #969BA0; } }
      .deznav .metismenu > li > a {
        font-weight: 400;
        display: inline-block;
        font-size: 15px;
        color: #3f4358; }
        .deznav .metismenu > li > a i {
          color: #969BA0; }
        .deznav .metismenu > li > a svg {
          max-width: 24px;
          max-height: 24px;
          height: 100%;
          margin-right: 5px;
          margin-top: -3px;
          color: var(--primary); }
        .deznav .metismenu > li > a g [fill] {
          fill: #8088a1; }
        .deznav .metismenu > li > a .nav-text {
          -webkit-transition: all 0.5s;
          -ms-transition: all 0.5s;
          transition: all 0.5s;
          display: inline-block; }
        .deznav .metismenu > li > a:hover .nav-text {
          transform: translateX(10px); }
      .deznav .metismenu > li > ul > li > ul a {
        padding-left: 4.2rem; }
        .deznav .metismenu > li > ul > li > ul a:before {
          border: 0;
          background: #a9a9a9;
          width: 10px;
          height: 1px;
          left: 44px; }
      .deznav .metismenu > li:hover > a, .deznav .metismenu > li:focus > a {
        color: var(--primary); }
        .deznav .metismenu > li:hover > a g [fill], .deznav .metismenu > li:focus > a g [fill] {
          fill: var(--primary); }
        .deznav .metismenu > li:hover > a i, .deznav .metismenu > li:focus > a i {
          color: var(--primary); }
      .deznav .metismenu > li.mm-active > a {
        background: var(--rgba-primary-1);
        color: var(--primary);
        font-weight: 500;
        box-shadow: none; }
        .deznav .metismenu > li.mm-active > a i {
          color: var(--primary);
          font-weight: 100; }
        .deznav .metismenu > li.mm-active > a g [fill] {
          fill: var(--primary); }
      [data-sidebar-style="compact"] .deznav .metismenu > li.mm-active > a i {
        background: var(--rgba-primary-1);
        color: var(--primary); }
    .deznav .metismenu li {
      position: relative; }
    .deznav .metismenu ul {
      transition: all .2s ease-in-out;
      position: relative;
      z-index: 1;
      padding: 0.5rem 0; }
      .deznav .metismenu ul a {
        padding-top: .5rem;
        padding-bottom: .5rem;
        position: relative;
        font-size: 14px;
        padding-left: 3.4rem;
        -webkit-transition: all 0.5s;
        -ms-transition: all 0.5s;
        transition: all 0.5s; }
        .deznav .metismenu ul a .badge {
          float: right;
          margin-right: 12px;
          line-height: 15px; }
        .deznav .metismenu ul a:before {
          position: absolute;
          height: 7px;
          width: 7px;
          border: 2px solid #a9a9a9;
          content: "";
          border-radius: 26px;
          top: 50%;
          transform: translateY(-50%);
          -webkit-transition: all 0.5s;
          -ms-transition: all 0.5s;
          transition: all 0.5s;
          left: 30px; }
          [data-sidebar-style="overlay"] .deznav .metismenu ul a:before {
            left: 40px; }
        @media only screen and (max-width: 1350px) {
          .deznav .metismenu ul a {
            padding-left: 5.2rem;
            font-size: 14px; }
            .deznav .metismenu ul a:before {
              left: 50px; } }
        @media only screen and (max-width: 767px) {
          .deznav .metismenu ul a {
            padding-left: 3.5rem;
            font-size: 14px; } }
        .deznav .metismenu ul a:hover, .deznav .metismenu ul a:focus, .deznav .metismenu ul a.mm-active {
          background: #f5f5f5;
          text-decoration: none;
          color: var(--primary); }
          .deznav .metismenu ul a:hover:before, .deznav .metismenu ul a:focus:before, .deznav .metismenu ul a.mm-active:before {
            border-color: var(--primary); }
    .deznav .metismenu a {
      position: relative;
      display: block;
      padding: 0.625rem 1.875rem;
      outline-width: 0;
      color: #3f4358;
      text-decoration: none; }
      @media only screen and (max-width: 767px) {
        .deznav .metismenu a {
          padding: 0.625rem 1.25rem; } }
    .deznav .metismenu .has-arrow:after {
      width: .5rem;
      height: .5rem;
      right: 1.875rem;
      top: 48%;
      border-color: inherit;
      -webkit-transform: rotate(-225deg) translateY(-50%);
      transform: rotate(-225deg) translateY(-50%); }
    .deznav .metismenu .has-arrow[aria-expanded=true]:after,
    .deznav .metismenu .mm-active > .has-arrow:after {
      -webkit-transform: rotate(-135deg) translateY(-50%);
      transform: rotate(-135deg) translateY(-50%); }


.mainLogo{
    width:120px;
}

.ImagoMainLogo{
    width: 50px !important;
}

.mainLogoFranquicias{
  width:60px;
}

.opacity-1{ opacity: 0.1; transition: 300ms all }
.opacity-1:hover{ opacity: 1; }

.opacity-2{ opacity: 0.2; transition: 300ms all }
.opacity-2:hover{ opacity: 1; }

.opacity-3{ opacity: 0.3; transition: 300ms all }
.opacity-3:hover{ opacity: 1; }

.opacity-4{ opacity: 0.4; transition: 300ms all }
.opacity-4:hover{ opacity: 1; }

.opacity-5{ opacity: 0.5; transition: 300ms all }
.opacity-5:hover{ opacity: 1; }

.opacity-6{ opacity: 0.6; transition: 300ms all }
.opacity-6:hover{ opacity: 1; }

.opacity-7{ opacity: 0.7; transition: 300ms all }
.opacity-7:hover{ opacity: 1; }

.opacity-8{ opacity: 0.8; transition: 300ms all }
.opacity-8:hover{ opacity: 1; }

.opacity-9{ opacity: 0.9; transition: 300ms all }
.opacity-9:hover{ opacity: 1; }

.fs-9{
    font-size: 11px !important;
    line-height: 1.8; 
    width: max-content;
  }
  .fs-12 {
    font-size: 14px !important;
    line-height: 1.6; }

    .text-white{
      color: #101C2E !important;
    }
.fs-10 {
        font-size: 12px !important;
        line-height: 1.6; }
  
  .fs-13 {
    font-size: 15px !important;
    line-height: 1.4; }
  
  .fs-14 {
    font-size: 16px !important;
    line-height: 1.5; }
    @media only screen and (max-width: 1199px) {
      .fs-14 {
        font-size: 12px !important; } }
  

.fs-15 {
    font-size: 17px !important;
    line-height: 1.5; }
  
  .fs-16 {
    font-size: 18px !important;
    line-height: 1.5; }
  
  .fs-18 {
    font-size: 22px !important;
    line-height: 1.5; }
  
  .fs-20 {
    font-size: 23px !important;
    line-height: 1.5; }
  
  .fs-22 {
    font-size: 24px !important;
    line-height: 1.5; }
  
  .fs-24 {
    font-size: 26px !important;
    line-height: 1.4; }
  
  .fs-26 {
    font-size: 27px !important;
    line-height: 1.4; }
  
  .fs-28 {
    font-size: 30px !important;
    line-height: 1.4; }
  
  .fs-30 {
    font-size: 30px !important;
    line-height: 1.4; }
  
  .fs-32 {
    font-size: 32px !important;
    line-height: 1.25; }
  
  .fs-34 {
    font-size: 34px !important;
    line-height: 1.25; }
  
  .fs-35 {
    font-size: 35px !important;
    line-height: 1.25; }
  
  .fs-36 {
    font-size: 36px !important;
    line-height: 1.25; }
  
  .fs-38 {
    font-size: 38px !important;
    line-height: 1.25; }
  
  .fs-46 {
    font-size: 46px !important;
    line-height: 1.25; }
  
  .fs-48 {
    font-size: 48px !important;
    line-height: 1.25; }
  
  .font-w100 {
    font-weight: 100; }
  
  .font-w200 {
    font-weight: 200; }
  
  .font-w300 {
    font-weight: 300; }
  
  .font-w400 {
    font-weight: 400; }
  
  .font-w500 {
    font-weight: 500; }
  
  .font-w600 {
    font-weight: 600; }
  
  .font-w700 {
    font-weight: 700; }
  
  .font-w800 {
    font-weight: 800; }
  
  .font-w900 {
    font-weight: 900; }